import React from "react";
import CharacterTemplate from "components/views/CharacterTemplate";
import AppContentContainer from "components/layouts/appLayout/AppContentContainer";
import SEO from "components/seo";
import ShortCharacterTemplate from "components/views/ShortCharacterTemplate";
import Accordian from "components/ui/accordian/Accordian";
import styles from "./Personas.module.scss";

import JSONData from "../../content/fvtt-Actor-Jin.json";
import BronsonData from "../../content/fvtt-Actor-Bronson_Droranath.json";
import MeriwetherData from "../../content/fvtt-Actor-Meriwether.json";
import EssaliaData from "../../content/fvtt-Actor-Essalia_d'Lyrandar.json";

const JinPage = () => {
  return (
    <AppContentContainer spacing="none">
      <SEO title={JSONData.name} />
      <CharacterTemplate data={JSONData} />
      <div className={styles.personas}>
        <h2>Personas</h2>
        <Accordian
          header={EssaliaData.name}
          headerClassName={styles.accordianHeader}
        >
          <ShortCharacterTemplate data={EssaliaData} hideTitle />
        </Accordian>
        <Accordian
          header={BronsonData.name}
          headerClassName={styles.accordianHeader}
        >
          <ShortCharacterTemplate data={BronsonData} hideTitle />
        </Accordian>
        <Accordian
          header={MeriwetherData.name}
          headerClassName={styles.accordianHeader}
        >
          <ShortCharacterTemplate data={MeriwetherData} hideTitle />
        </Accordian>
      </div>
    </AppContentContainer>
  );
};

export default JinPage;
