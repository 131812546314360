import React from "react";
import styles from "components/views/Character.module.scss";

interface Props {
  data: any;
  hideTitle?: boolean;
}

const ShortCharacterTemplate = ({ data, hideTitle = false }: Props) => {
  const {
    name,
    // img,
    token: { img },
    data: {
      details: { biography, race, background, alignment },
    },
  } = data;

  return (
    <div className={styles.content}>
      {!hideTitle && <h1 className={styles.title}>{name}</h1>}

      <div className={styles.attribute}>
        <b>Race: </b>
        {race}
      </div>
      <div className={styles.attribute}>
        <b>Background: </b>
        {background}
      </div>
      <div className={styles.attribute}>
        <b>Alignment: </b>
        {alignment}
      </div>
      <div className={styles.imgBorder}>
        <div className={styles.img}>
          <img alt="character-token" src={img} />
          <div className={styles.imgOverlay} />
        </div>
      </div>
      <h2 className={styles.bioHeader}>Biography</h2>
      <div
        className={styles.bio}
        dangerouslySetInnerHTML={{ __html: biography.value }}
      />
    </div>
  );
};

export default ShortCharacterTemplate;
