import React, { memo, useState } from "react";
import cx from "classnames";
import { motion } from "framer-motion";
import styles from "./Accordian.module.scss";
import IconExpandArrow from "components/ui/icons/IconExpandArrow";

interface IAccordian {
  header?: any;
  children: any;
  height?: string;
  headerClassName?: string;
  className?: string;
  defaultState?: boolean;
  center?: boolean;
}

const Accordian = memo(
  ({
    children,
    header,
    height = "auto",
    headerClassName,
    className,
    defaultState = false,
    center = false,
  }: IAccordian) => {
    const [expand, setExpand] = useState(defaultState);

    return (
      <>
        {header && (
          <div
            className={cx(
              styles.header,
              center && styles.center,
              headerClassName
            )}
            onClick={() => setExpand(!expand)}
          >
            <div className={styles.headerContent}>{header}</div>

            <IconExpandArrow
              className={cx(styles.expandArrow, expand && styles.expanded)}
            />
          </div>
        )}

        <motion.div
          className={cx(styles.content, className)}
          initial="hidden"
          variants={{
            visible: { opacity: 1, height: height },
            hidden: { opacity: 0, height: 0 },
          }}
          animate={expand ? "visible" : "hidden"}
          transition={{
            height: { type: "tween", duration: 0.3 },
            opacity: { duration: 0.15 },
          }}
          aria-hidden={!expand}
        >
          <div>{children}</div>
        </motion.div>
      </>
    );
  }
);

export default Accordian;
